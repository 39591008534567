<template>
  <MainContent>
    <div class="container-fluid" v-if="selectActiveCompany" :key="selectActiveCompany._id">
      <PromoView v-if="showOrgPromos" />
      <PromoList v-else />
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import PromoList from '@/components/Modules/Promo/PromoList'
import PromoView from '@/components/Modules/PromoV2/PromoView'
import { trackEvent } from '@/lib/analytics'
import { createNamespacedHelpers } from 'vuex'

const FeatureFlagModule = createNamespacedHelpers('featureFlag')
const CompanyModule = createNamespacedHelpers('company')
const OrganizationModule = createNamespacedHelpers('organization')

export default {
  name: 'PromosPage',
  components: {
    MainContent,
    PromoList,
    PromoView,
  },
  mounted() {
    trackEvent(this.$intercom, 'Promos')
  },
  computed: {
    ...FeatureFlagModule.mapGetters(['isEnabled']),
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...OrganizationModule.mapGetters(['selectOrganization']),
    showOrgPromos() {
      return (
        (this.selectActiveCompany?._id === 'ALL_COMPANIES' && !!this.selectOrganization) ||
        (this.selectActiveCompany?._id !== 'ALL_COMPANIES' &&
          this.isEnabled('HIDE_LEGACY_CAMPAIGNS'))
      )
    },
  },
}
</script>
