<template>
  <FadeTransition>
    <div v-if="initialized" class="row justify-content-center">
      <div class="col-12" v-if="promosToShow.length">
        <PromoHeader />
      </div>
      <div class="col-12" v-if="promosToShow.length">
        <PromoTable v-if="!selectIsLoadingPromos" />
        <b-skeleton-table :columns="4" :rows="6" v-else />
      </div>
      <div class="col-12 col-lg-10 mt-6" v-if="!promosToShow.length">
        <PromoOffstate />
      </div>
    </div>

    <!-- skeleton loader -->
    <div v-else>
      <div class="d-flex justify-content-between mt-4">
        <b-skeleton width="150px" height="50px" />
        <b-skeleton width="150px" height="50px" />
      </div>
      <div class="d-flex mt-4">
        <div v-for="x in 5" :key="x" class="mr-2">
          <b-skeleton width="50px" height="30px" />
        </div>
      </div>
      <hr />
      <b-skeleton-table :columns="4" :rows="6" />
    </div>
  </FadeTransition>
</template>

<script>
import FadeTransition from '@/components/Transitions/FadeTransition'
import PromoHeader from '@/components/Modules/PromoV2/PromoHeader'
import PromoTable from '@/components/Modules/PromoV2/PromoTable'
import PromoOffstate from '@/components/Modules/PromoV2/PromoOffstate'
import { createNamespacedHelpers } from 'vuex'

const PromoModuleV2 = createNamespacedHelpers('promoV2')
const CompanyModule = createNamespacedHelpers('company')
const ConversationModuleV2 = createNamespacedHelpers('conversationV2')

export default {
  name: 'PromoView',
  props: ['companyId'],
  components: {
    FadeTransition,
    PromoHeader,
    PromoTable,
    PromoOffstate,
  },
  data: () => ({
    initialized: false,
  }),
  async mounted() {
    await this.fetchPromos()
    this.initialized = true
  },
  computed: {
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...ConversationModuleV2.mapGetters(['selectActiveConversation']),
    ...PromoModuleV2.mapGetters(['selectPromos', 'selectIsLoadingPromos']),
    activeCompanyId() {
      return this.selectActiveConversation
        ? this.selectActiveConversation.company
        : this.selectActiveCompany._id
    },
    promosToShow() {
      return this.selectActiveCompany._id === 'ALL_COMPANIES'
        ? this.selectPromos
        : this.selectPromos.filter((promo) => promo.company === this.activeCompanyId)
    },
  },
  methods: {
    ...PromoModuleV2.mapActions(['fetchPromos']),
  },
}
</script>

<style scoped lang="scss"></style>
