<template>
  <FadeTransition>
    <div v-if="initialized" class="row justify-content-center">
      <div class="col-12" v-if="promosToShow.length">
        <PromoListHeader />
      </div>
      <div class="col-12" v-if="promosToShow.length">
        <!-- <PromoListContent /> -->
        <PromoListTable />
      </div>
      <div class="col-12 col-lg-10 mt-6" v-if="!promosToShow.length">
        <PromoOffstate />
      </div>
    </div>
  </FadeTransition>
</template>

<script>
import FadeTransition from '@/components/Transitions/FadeTransition'
import PromoListHeader from '@/components/Modules/Promo/PromoListHeader'
import PromoListContent from '@/components/Modules/Promo/PromoListContent'
import PromoListTable from '@/components/Modules/Promo/PromoListTable'
import PromoOffstate from '@/components/Modules/Promo/PromoOffstate'
import { createNamespacedHelpers } from 'vuex'
const PromoModule = createNamespacedHelpers('promo')
const CompanyModule = createNamespacedHelpers('company')
const ConversationModule = createNamespacedHelpers('conversation')

export default {
  name: 'PromosPage',
  props: ['companyId'],
  components: {
    FadeTransition,
    PromoListHeader,
    PromoListContent,
    PromoListTable,
    PromoOffstate,
  },
  data: () => ({
    initialized: false,
  }),
  computed: {
    activeCompanyId() {
      return this.activeConversation ? this.activeConversation.company : this.activeCompany._id
    },
    ...CompanyModule.mapGetters(['activeCompany']),
    ...ConversationModule.mapGetters(['activeConversation']),
    ...PromoModule.mapGetters(['promos']),
    promosToShow() {
      let self = this
      return this.promos.filter((promo) => promo.company == self.activeCompanyId)
    },
  },
  watch: {
    activeCompanyId: {
      immediate: true,
      async handler() {
        await this.queryPromos()
        this.initialized = true
      },
    },
  },
  methods: {
    ...PromoModule.mapActions(['queryPromos']),
  },
}
</script>

<style scoped lang="scss"></style>
