<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <h6 class="header-pretitle">Take Action</h6>
          <h1 class="header-title">Offers</h1>
        </div>
        <div class="col-auto">
          <router-link to="/create-promo" class="btn btn-primary">Create Offer</router-link>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col">
          <ul class="nav nav-tabs nav-overflow header-tabs">
            <li class="nav-item">
              <a
                class="nav-link text-capitalize"
                :class="{ active: sourceFilter == 'all' }"
                href="javascript:;"
                v-on:click="setSourceFilter('all')"
                >All
                <span class="badge badge-pill badge-soft-secondary mt--1">{{
                  this.promos.length
                }}</span>
              </a>
            </li>
            <li class="nav-item" v-for="(value, key, index) in sourceFilters" :key="index">
              <a
                class="nav-link text-capitalize"
                :class="{ active: sourceFilter == key }"
                href="javascript:;"
                v-on:click="setSourceFilter(key)"
                >{{ value.name }}
                <span class="badge badge-pill badge-soft-secondary mt--1">{{ value.count }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const promoModule = createNamespacedHelpers('promo')

export default {
  name: 'PromoListHeader',
  computed: {
    ...promoModule.mapState(['promos']),
    ...promoModule.mapState(['sourceFilter']),
    sourceFilters() {
      const statuses = {}

      for (const promo of this.promos) {
        if (promo.disabled === true) continue

        if (promo.source == '') {
          if (statuses['']) statuses[''].count = statuses[''].count + 1
          else {
            statuses[''] = {}
            statuses[''].count = 1
            statuses[''].name = 'Global'
          }
          continue
        }

        if (statuses[promo.source]) {
          statuses[promo.source].count = statuses[promo.source].count + 1
        } else {
          statuses[promo.source] = {}
          statuses[promo.source].count = 1
          statuses[promo.source].name = promo.source
        }
      }

      return statuses
    },
  },
  methods: {
    ...promoModule.mapMutations(['setSourceFilter']),
  },
}
</script>
