<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <b-table
          :fields="fields"
          :items="filteredPromos"
          responsive
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :table-class="['card-table', 'table-nowrap']"
          small
        >
          <template #head()="{ label, field: { key, sortable, tooltip } }">
            <span class="cursor-pointer" v-b-tooltip.hover :title="tooltip">
              {{ label }}
            </span>
            <template v-if="sortable">
              <template v-if="sortBy !== key">
                <i class="fe fe-arrow-down"></i>
                <i class="fe fe-arrow-up"></i>
              </template>
              <i v-else-if="sortDesc" class="fe fe-arrow-down" style="font-weight: bold"></i>
              <i v-else class="fe fe-arrow-up" style="font-weight: bold"></i>
            </template>
          </template>

          <template #cell(source)="{ value }">
            <span class="badge text-capitalize badge-soft-primary" style="font-size: 12px">
              {{ value }}
            </span>
          </template>

          <template #cell(status)="{ value }">
            <span class="badge text-capitalize" style="font-size: 12px" :class="statusClass(value)">
              {{ value }}
            </span>
          </template>

          <template
            #cell(codesAvailable)="{ item: { availableInStoreCodes, availableOffPremiseCodes } }"
          >
            <div v-if="!availableInStoreCodes && !availableOffPremiseCodes">N/A</div>
            <div v-else>
              <div v-if="availableInStoreCodes">
                In-Store: {{ availableInStoreCodes.toLocaleString() }}
              </div>
              <div v-if="availableOffPremiseCodes">
                Off-Prem: {{ availableOffPremiseCodes.toLocaleString() }}
              </div>
            </div>
          </template>

          <template #cell(createdat)="data">
            {{ $moment(data.item.createdAt).format('M/D/YY') }}
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-end">
              <button
                class="btn btn-outline-primary btn-sm btn-min-width"
                @click="editPromo(data.item)"
              >
                Edit
              </button>
              <div class="d-inline ml-3">
                <PromoListTableDropdown
                  :promo="data.item"
                  @toggle="toggle(data.item)"
                  @delete="remove(data.item)"
                />
              </div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import PromoService from '@/services/PromoService'
import PromoListTableDropdown from './PromoListTableDropdown'
import { createNamespacedHelpers } from 'vuex'
const PromoModule = createNamespacedHelpers('promo')

export default {
  name: 'PromoListContent',
  components: {
    PromoListTableDropdown,
  },
  data: () => ({
    fields: [
      {
        key: 'title',
        formatter: (value, key, item) => {
          if (item.nickname) {
            return item.nickname
          } else {
            return item.title
          }
        },
        sortable: true,
        label: 'Name',
        class: 'align-middle',
      },
      {
        key: 'source',
        formatter: (value, key, item) => {
          if (!item.source) {
            return 'Global'
          } else {
            return item.source
          }
        },
        sortByFormatted: true,
        sortable: true,
        class: 'align-middle',
        tooltip: 'Where the offer will display and can be sent',
      },
      {
        key: 'status',
        formatter: (value, key, item) => {
          if (!item.disabled) {
            return 'Active'
          } else {
            return 'Disabled'
          }
        },
        sortByFormatted: true,
        sortable: true,
        class: 'align-middle',
      },
      { key: 'totalIssued', label: 'Issued', sortable: true, class: 'align-middle' },
      { key: 'totalRedeemed', label: 'Redeemed', sortable: true, class: 'align-middle' },
      {
        key: 'codesAvailable',
        label: 'Codes Available',
        sortable: false,
        class: 'align-middle',
        tooltip: 'The number of unique codes that have been uploaded and can still be assigned',
      },
      {
        key: 'redemption %',
        formatter: (value, key, item) => {
          if (item.totalIssued > 0) {
            return `${Math.ceil((item.totalRedeemed / item.totalIssued) * 100)}%`
          } else {
            return '0%'
          }
        },
        sortByFormatted: true,
        sortable: true,
        class: 'align-middle',
      },
      { key: 'createdAt', label: 'created', sortable: true, class: 'align-middle' },
      { key: 'actions', label: '', class: ['align-middle', 'action-width'] },
    ],
    sortDesc: false,
    sortBy: 'createdAt',
  }),
  computed: {
    ...PromoModule.mapState(['promos', 'sourceFilter']),
    filteredPromos() {
      if (this.sourceFilter === 'all') return this.promos

      return this.promos.filter((promo) => {
        return promo.source === this.sourceFilter && !promo.disabled
      })
    },
  },
  methods: {
    ...PromoModule.mapMutations(['setPromo']),
    ...PromoModule.mapActions(['queryPromos']),
    statusClass(status) {
      if (status === 'Active') {
        return 'badge-soft-success'
      } else {
        return 'badge-soft-danger'
      }
    },
    editPromo(promo) {
      this.setPromo(promo)
      this.$router.push({ name: 'create-promo' })
    },
    async toggle(promo) {
      try {
        await PromoService.toggle(promo._id)
        this.queryPromos()
        this.$notify({
          title: 'Success',
          text: `Your promo was updated.`,
        })
      } catch (err) {
        this.$notify({
          title: 'Failed to update promo.',
          text: 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
    async remove(promo) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete promo "${promo.title}"?`, {
          title: 'Please Confirm!',
          okVariant: 'danger',
          okTitle: 'Delete',
          footerClass: 'p-4',
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            try {
              await PromoService.remove(promo._id)
              this.queryPromos()
              this.$notify({
                title: 'Success',
                text: `Your promo was deleted.`,
              })
            } catch (err) {
              this.$notify({
                title: 'Failed to delete promo.',
                text: 'Please contact us for assistance.',
                type: 'error',
              })
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep tr > [aria-sort] {
  background-image: none !important;
}
.btn-min-width {
  min-width: 70px;
}
</style>
