<template>
  <div class="header">
    <div class="header-body">
      <div class="row align-items-center">
        <div class="col">
          <div class="d-flex align-items-center">
            <h1 class="header-title">Offers</h1>
            <div
              v-if="selectActiveCompany._id === 'ALL_COMPANIES'"
              class="dropdown d-inline-block ml-4 filter-container"
            >
              <SingleSelector
                :placeholder="'Select Organization'"
                :options="selectOrganizations"
                :initial="initialOrganization"
                @update="organizationUpdate"
              />
            </div>
          </div>
        </div>
        <div class="col-auto">
          <router-link to="/create-promo" class="btn btn-primary">Create Offer</router-link>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col">
          <ul class="nav nav-tabs nav-overflow header-tabs">
            <li class="nav-item">
              <a
                class="nav-link text-capitalize"
                :class="{ active: selectSourceFilter == 'all' }"
                href="javascript:;"
                @click="setSourceFilter('all')"
              >
                All
                <span class="badge badge-pill badge-soft-secondary mt--1">{{
                  this.selectPromos.length
                }}</span>
              </a>
            </li>
            <li class="nav-item" v-for="(value, key, index) in sourceFilters" :key="index">
              <a
                class="nav-link text-capitalize"
                :class="{ active: selectSourceFilter == key }"
                href="javascript:;"
                @click="setSourceFilter(key)"
              >
                {{ value.name }}
                <span class="badge badge-pill badge-soft-secondary mt--1">{{ value.count }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleSelector from '@/components/Modules/Filters/SingleSelectFilter_Object'
import { createNamespacedHelpers } from 'vuex'

const PromoModuleV2 = createNamespacedHelpers('promoV2')
const CompanyModule = createNamespacedHelpers('company')
const OrganizationModule = createNamespacedHelpers('organization')

export default {
  name: 'PromoHeader',
  components: { SingleSelector },
  computed: {
    ...PromoModuleV2.mapGetters(['selectPromos', 'selectSourceFilter']),
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...OrganizationModule.mapGetters(['selectOrganizations', 'selectOrganization']),
    sourceFilters() {
      const statuses = {}

      for (const promo of this.selectPromos) {
        if (promo.disabled === true) continue

        if (promo.source == '') {
          if (statuses['']) statuses[''].count = statuses[''].count + 1
          else {
            statuses[''] = {}
            statuses[''].count = 1
            statuses[''].name = 'Global'
          }
          continue
        }

        if (statuses[promo.source]) {
          statuses[promo.source].count = statuses[promo.source].count + 1
        } else {
          statuses[promo.source] = {}
          statuses[promo.source].count = 1
          statuses[promo.source].name = promo.source
        }
      }

      return statuses
    },
    initialOrganization() {
      return this.selectOrganization?._id || null
    },
  },
  methods: {
    ...PromoModuleV2.mapActions(['setSourceFilter', 'fetchPromos', 'setIsLoadingPromos']),
    ...OrganizationModule.mapActions(['setOrganization']),
    async organizationUpdate(org) {
      this.setIsLoadingPromos(true)
      await this.setOrganization(org)
      await this.fetchPromos()
    },
  },
}
</script>
