<template>
  <div>
    <div role="list" class="card" v-for="promo in filteredPromoss" :key="promo._id">
      <div role="listitem" data-testid="promo-item" class="card-body">
        <div class="row align-items-center">
          <div class="col d-flex align-items-center">
            <!-- Heading -->
            <h2 class="mb-0 text-capitalize">
              {{ promo.title }}
            </h2>
            <span class="badge ml-2 text-capitalize badge-soft-primary">{{ promo.source }}</span>
          </div>
          <div class="col-auto">
            <div class="custom-control custom-checkbox-toggle mr-0">
              <input
                class="custom-control-input"
                :id="promo._id"
                type="checkbox"
                @change="toggle(promo)"
                :checked="!promo.disabled"
                aria-label="toggle disable"
              /><label class="custom-control-label" :for="promo._id"></label>
            </div>
          </div>
          <div class="col-auto">
            <div class="dropdown">
              <a
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                aria-label="promo actions"
                class="dropdown-ellipses dropdown-toggle"
              >
                <i class="fe fe-more-vertical"></i>
              </a>
              <div role="menu" class="dropdown-menu dropdown-menu-right">
                <a href="#" @click="editPromo(promo)" role="menuitem" class="dropdown-item">
                  Edit
                </a>
                <a href="#!" @click="remove(promo._id)" role="menuitem" class="dropdown-item">
                  Delete
                </a>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div class="row align-items-center">
          <div class="col">
            <div class="row">
              <div class="col-sm-2">
                <h6 class="card-title text-uppercase text-muted mb-2">Total Issued</h6>
                <span class="h2 mb-0">{{ promo.totalIssued }}</span>
              </div>
              <div class="col-sm-2">
                <h6 class="card-title text-uppercase text-muted mb-2">Total Redeemed</h6>
                <span class="h2 mb-0">{{ promo.totalRedeemed }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PromoService from '@/services/PromoService'
import { createNamespacedHelpers } from 'vuex'
const PromoModule = createNamespacedHelpers('promo')

export default {
  xname: 'PromoListContent',
  components: {},
  data: () => ({}),
  computed: {
    ...PromoModule.mapState(['promos', 'sourceFilter']),
    filteredPromoss() {
      if (this.sourceFilter === 'all') return this.promos

      return this.promos.filter((promo) => {
        return promo.source === this.sourceFilter && !promo.disabled
      })
    },
  },
  methods: {
    ...PromoModule.mapMutations(['setPromo']),
    ...PromoModule.mapActions(['queryPromos']),
    editPromo(promo) {
      this.setPromo(promo)
      this.$router.push({ name: 'create-promo' })
    },
    async toggle(promo) {
      try {
        await PromoService.toggle(promo._id)
        this.queryPromos()
        this.$notify({
          title: 'Success',
          text: `Your promo was updated.`,
        })
      } catch (err) {
        this.$notify({
          title: 'Failed to update promo.',
          text: 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
    async remove(promoId) {
      try {
        await PromoService.remove(promoId)
        this.queryPromos()
        this.$notify({
          title: 'Success',
          text: `Your promo was deleted.`,
        })
      } catch (err) {
        this.$notify({
          title: 'Failed to delete promo.',
          text: 'Please contact us for assistance.',
          type: 'error',
        })
      }
    },
  },
}
</script>
